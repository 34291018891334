<template>
  <tr ripple>
    <td class="justify-center" style="padding-top: 4px; cursor: pointer;">
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" >
            <v-icon>settings</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile 
            @click="editBusiness()" 
            v-prms="{'id': getIdPermission('update', config.config), mode: 'disabled'}"
          >
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="orange">edit</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Editar</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="$WiApiDelete({ uri: config.config.apiUrl, id: business.id })">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="red">delete</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Apagar</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile
            @click="copyBusiness()" 
            v-prms="{'id': getIdPermission('create', config.config), mode: 'disabled'}"
          >
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="green">content_copy</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Duplicar</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="$WiDialog(wiDialogs.events(props.item))">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="blue">message</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Visualizar Notas</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="$WiDialog(wiDialogs.files(props.item))">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>attachment</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Arquivos/Anexos</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </td>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(business.id)">
      {{business.id}}
    </td>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(business.id)">
      <span v-if="business.person.type === 'Juridica'">PJ</span>
      <span v-if="business.person.type === 'Fisica'">PF</span>
    </td>
    <td class="justify-left" style="cursor: pointer;">
      <v-btn outline @click="copyBusiness()" 
            v-prms="{'id': getIdPermission('create', config.config), mode: 'disabled'}">
        Usar modelo de orçamento
      </v-btn>
    </td>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(business.id)">
      <div style="text-align: center;">
        <v-icon>{{business.status.icon}}</v-icon><br>
        <v-tooltip bottom>
          <template slot="activator">
            <strong :style="{ color: business.status.color }">
              {{ business.status.name | stringlength(15) }}
            </strong>
          </template>
          <span>{{ business.status.name }}</span>
        </v-tooltip>
      </div>
    </td>
    <td class="justify-center" style="cursor: pointer; text-align: center !important;" @click="goToRouteDetail(business.id)">
      <div v-if="business.is_template">
        <span class="business__date business__is-template">Template</span><br>
      </div>
      <div v-else-if="business.new">
        <span class="business__date business__date--new">Novo</span><br>
      </div>
      <span v-if="business.stage == 1">{{business.opened_at | moment('DD/MM/YY')}}</span>
      <span v-if="business.stage == 2">{{business.closed_at | moment('DD/MM/YY')}}</span>
      <span v-if="business.stage == 3">{{business.canceled_at | moment('DD/MM/YY')}}</span>
    </td>
    <td class="justify-center" style="cursor: pointer; text-align: center !important;" @click="goToRouteDetail(business.id)">
      <span v-if="business.stage == 1 && business.expired" class="business__date business__date--expired">Expirado</span>
      <div v-if="business.info.name != 'budget' && business.info.name != 'quotation'">
        <span>{{business.date_end_format}}</span><br>
      </div>
      <div v-if="business.info.name == 'budget' || business.info.name == 'quotation'">
        <span>{{business.date_validity_format}}</span><br>
      </div>
    </td>
    <td class="justify-center" style="padding-top: 4px; cursor: pointer;" @click="goToRouteDetail(business.id)">
      <span>{{ business.external_code || '- - -' | stringlength(20) }}</span>
    </td>
    <td class="justify-left" style="padding-top: 4px; cursor: pointer;" @click="goToRouteDetail(business.id)">
      <v-tooltip bottom>
        <template slot="activator">
          <span v-if="business.collaborator">
            {{ business.collaborator.name | stringlength(19) }}
          </span><br>
          <strong v-if="business.category" :style="{ color: business.category.color }">
            {{ business.category.name | stringlength(19) }}
          </strong>
        </template>
        <span>{{ business.collaborator.name }} | {{ business.category.name }}</span>
      </v-tooltip>
    </td>
    <td class="justify-left" style="padding-top: 4px; cursor: pointer;">
      <strong>{{ business.total_liquid | money }}</strong>
    </td>
  </tr>
</template>
<script>
  import { Auth } from '@/default/service/Auth'
  export default {
    name: 'Businesses',
    data () {
      return {
        wiDialogs: {
          files: (business) => {
            return {
              title: 'Arquivos/Anexos',
              icon: 'attachment',
              component: {
                content: () => import('@/default/component/WiFile/WiFileList'),
                props: {
                  index: this.config.config.name + '-fileList',
                  api: {
                    url: 'sales/sale/file',
                    params: '?order=id,desc&where=business_id,' + business.id
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Arquivo',
                action: () => {
                  this.$WiDialog({
                    title: 'Arquivos/Anexos ',
                    icon: 'attachment',
                    component: {
                      content: () => import('@/default/component/WiFile/WiFile'),
                      props: {
                        business_id: business.id
                      }
                    },
                    onClose: () => {
                      this.$WiView(this.config.config.name + '-fileList').refresh()
                    }
                  })
                }
              }
            }
          },
          events: (business) => {
            return {
              title: 'Anotações/Eventos ',
              icon: 'message',
              component: {
                content: () => import('@/default/component/WiTimeLine/WiTimeLine'),
                props: {
                  index: this.config.config.name + '-event',
                  api: {
                    url: 'sales/sale/event',
                    params: '?order=id,desc&where=business_id,' + business.id
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Nota',
                action: () => {
                  this.$WiEditDialog({
                    wiConfig: this.config.config.name + '-event',
                    onSubmit: () => {
                      this.$WiView(this.config.config.name + '-event').refresh()
                    },
                    data: {business_id: business.id}
                  })
                }
              }
            }
          }
        }
      }
    },
    computed: {
      business () {
        return this.props.item
      }
    },
    methods: {
      goToRouteDetail: function (id) {
        var url = this.config.config.urlDetails
        url.params['id'] = id
        this.$router.push(url)
      },
      copyBusiness: function () {
        this.$WiEditDialog({
          wiConfig: this.config.store,
          onSubmit: this.config.refresh,
          data: {
            ...this.props.item,
            id: null,
            uuid: null,
            business_id: null,
            person_id: null,
            person: null,
            collaborator_id: Auth.user().id,
            is_template: false,
            date_start: new Date().toISOString().split('T')[0], //current date
            date_end: null,
            date_validity: null,
            copy_business_id: this.props.item.id,
            copy_business: 'd711a3c2-3809-4ef2-9052-e34fb11ac908',
            created_at: null,
            updated_at: null,
            opened_at: null,
            closed_at: null,
            canceled_at: null,
            ...this.config?.wiEditData || {}
          }
        })
      },
      editBusiness: function () {
        this.$WiEditDialog({
          wiConfig: this.config.store,
          onSubmit: this.config.refresh,
          data: this.props.item
        })
      },
      getIdPermission: function (action, config) {
        if (config && config.id) {
          return config.id[action] || config.id
        }
        return null
      }
    },
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style>
.business__date {
  border-width: 1px;
  border-style: solid;
  padding: 0px 3px;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
}
.business__date--new {
  border-color: green;
  background: rgba(0, 128, 0, 0.589);
}
.business__date--expired {
  border-color: red;
  background: rgba(255, 0, 0, 0.507);
}
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
.business__is-template {
  background: orange;
  border-color: rgb(145, 94, 0);
}
</style>